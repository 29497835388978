import React from 'react'
import css from './style.module.scss'
import cn from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as popupActions from 'components/popups/actions'
import { Button } from 'components/ui'

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(popupActions, dispatch)
})

export default connect(null, mapDispatchToProps)(PopupAlert)

function PopupAlert({
  popupActions,
  title,
  text = '',
  buttonText = 'Ok',
  onClickButton
}) {

  const onClickButtonHandler = e => {
    if (onClickButton) {
      onClickButton(e)
    } else {
      popupActions.closePopup()
    }
  }

  return (
    <div className={css.popupAlert}>

      {title &&
        <p className={cn('h2-like popup-title', css.alertTitle)}>{title}</p>
      }

      <div
        className={css.popupBody}
      >
        {text && 
          <p>{text}</p>
        }

      <Button 
        onClick={onClickButtonHandler}
        text={buttonText}
       />
      </div>

    </div>
  )
}

import React from 'react'
import css from './style.module.scss'
import cn from 'classnames'
import Icon1 from './img/icon1.inline.svg'
import Icon2 from './img/icon2.inline.svg'
import Icon3 from './img/icon3.inline.svg'
import Icon4 from './img/icon4.inline.svg'
import { Button } from 'components/ui'
import Pic from './img/shabbath-set.jpg'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { navigate } from 'gatsby'
import { openLogInPopup } from 'components/user/actions'

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  hasOrder: state.orders.has_order,
})

const mapDispatchToProps = dispatch => ({
  openLogInPopup: bindActionCreators(openLogInPopup, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(shabbathSetContent)

function shabbathSetContent({
  openLogInPopup,
  authToken,
  hasOrder,
}) {
  const onClickButtonHandler = e => {
    if (authToken) {
      navigate('/make-order/')
    } else {
      openLogInPopup()
    }
  }

  return (
    <div className={css.shabbathSetContent}>
      <div className={cn(css.grid, 'container')}>

        <div className={css.one}>
          <h2>Вносим святость субботы к себе домой</h2>
        </div>
        <div className={css.two}>
          <p>В наборе вы получите необходимое для проведения шабата у себя дома.</p>
        </div>

        <div className={cn(css.image, css.three)}>
          <img src={Pic} alt="shabbath set pic" />
        </div>

        <div className={css.four}>
          <p className="bold">В составе набора:</p>

          <div className={css.set}>
            <div className={css.setItem}>
              <Icon1 />
              <p>Кошерный виноградный сок</p>
            </div>
            <div className={css.setItem}>
              <Icon2 />
              <p>Хала</p>
            </div>
            <div className={css.setItem}>
              <Icon3 />
              <p>Шабатные свечи</p>
            </div>
            <div className={css.setItem}>
              <Icon4 />
              <p>Инструкция</p>
            </div>
          </div>
        </div>

        {
          hasOrder &&
            <div className={cn(css.buttonWrap, css.five)}>
              <Button className={css.button} text="Оставить заявку" onClick={onClickButtonHandler} />
            </div>
        }

      </div>
    </div>
  )
}

import React from 'react'
import { Button } from 'components/ui'
import cn from 'classnames'
import css from './block.module.scss'
import img6 from '../../../images/img6.jpg'
import { Link } from 'gatsby'

export default function () {
  return (
    <div className={'container'}>
      <div className={css.donateBlock}>
        
        <div className={css.section1}>
          <div className={css.text}>
            <h2>Поддержать проект</h2>
            <p>
              Помогите внести свет Шабата в еще один дом!
            </p>
          </div>

          <Link className={css.button} to="/donate">
            <Button text={'Поддержать проект'} />
          </Link>
        </div>

        <div className={cn(css.section2, 'desktop')}>
          <img src={img6} alt="Donate" />
        </div>
      </div>
    </div>
  )
}

import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { navigate } from 'gatsby'
import { openLogInPopup } from 'components/user/actions'

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  hasOrder: state.orders.has_order,
})

const mapDispatchToProps = dispatch => ({
  openLogInPopup: bindActionCreators(openLogInPopup, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(ShabbathSetBanner)

function ShabbathSetBanner({
  openLogInPopup,
  authToken,
  hasOrder,
}) {
  function onClickButtonHandler() {
    if (authToken) {
      navigate('/make-order/')
    } else {
      openLogInPopup()
    }
  }

  return (
    <div className={css.banner}>
      <h2>Отметьте шабат у себя дома с помощью специального набора</h2>

      {
        hasOrder &&
          <div className={css.buttonWrap}>
            <Button
              className={css.button}
              text="Оставить заявку"
              onClick={onClickButtonHandler}
            />
          </div>
      }
    </div>
  )
}

import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'
import cn from 'classnames'
import PropTypes from 'prop-types'
import HeroPic from './img/bg.png'

HeroView.propTypes = {
  onClickButton: PropTypes.func.isRequired
}

export default function HeroView({
  onClickButton,
  hasOrder,
}) {
  return (
    <div className={css.hero}>
      <div className={cn(css.container, 'container')}>
        <div className={css.bgPic}>
          <img src={HeroPic} alt="hero pic" />
        </div>

        <div className={css.textWrap}>
          <h1 className={css.title}>Превратим субботу в ШАБАТ!</h1>
          <p className={css.descr}>
            Отметьте шабат у себя дома с помощью специального набора
          </p>
          {
            hasOrder &&
              <div className={css.buttonWrap}>
                <Button
                  className={css.button}
                  text="Оставить заявку"
                  onClick={onClickButton}
                />
              </div>
          }
        </div>
        
      </div>
    </div>
  )
}

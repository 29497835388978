import React from 'react'
import css from './style.module.scss'
import { Button } from 'components/ui'
import cn from 'classnames'

export default function () {
  return (
    <div className={css.relatedVideo}>

      <div className={cn('container')}>
        <h3>Советуем посмотреть</h3>

        <div className={css.flex}>
          <div className={css.item}>
            <p>Как делать Кидуш?</p>
            <div className={css.videoBlock}>
              <iframe src="https://www.youtube.com/embed/yrMZdSA1XXQ"
                      frameBorder="0"
                      title="Как делать Кидуш?"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>

            </div>
          </div>
          <div className={css.item}>
            <p>Как зажигать субботние свечи?</p>
            <div className={css.videoBlock}>
              <iframe src="https://www.youtube.com/embed/7hOS_taRDgk"
                      frameBorder="0"
                      title="Как зажигать субботние свечи?"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen></iframe>
            </div>
          </div>
        </div>

        <div className={css.buttonWrap}>
          <a href="/what-is-shabbath" aria-label="check">
            <Button ghost={true} text={'Узнать о Шабате больше'} />
          </a>
        </div>

      </div>

    </div>
  )
}

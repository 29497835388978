import React from 'react'
import { Button } from 'components/ui'
import cn from 'classnames'
import css from './style.module.scss'
import ShabbathPic from '../../../images/img4.jpg'

export default function () {
  return (
    <div className={cn(css.whatIsShabbath, 'container')}>
      
      <div className={cn(css.section2)}>
        <div className={css.text}>
          <h2>Что такое Шаббат?</h2>
          <p>
            Всё, что составляет нашу удивительную вселенную, было сотворено за
            шесть дней «работы». В эти дни Творец показал часть Своей великой
            силы и произвёл удивительную вещь – сотворил из ничего тот мир, в
            котором мы живём.
          </p>
          <p>
            После окончания сотворения мира в шестой день Творец
            как бы отдыхал от работы творения в течение целых суток, покоился
            и не совершал работу. Он благословил седьмой день и придал ему
            особую святость.
          </p>
          <p>
            Творец предназначил седьмой день для покоя, святости и
            духовного подъёма. В этот день Его любимые создания могут узнать
            Его, их Творца, очиститься от телесности и материальности и хотя бы
            один день в неделю прожить духовной жизнью, более чистой и святой.
            В память о сотворении мира Тора заповедала нам почитать седьмой
            день недели, отдыхать в этот день и не совершать работы.
          </p>
        </div>

        <a className={css.knowMore} href="/what-is-shabbath">
          <Button ghost={true} text={'Узнать больше'} />
        </a>
      </div>
      
      <div className={css.section1}>
        <img src={ShabbathPic} alt="what is shabbat" />
      </div>

    </div>
  )
}

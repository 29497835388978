import React, { Component } from 'react'
import css from './style.module.scss'
import { slide as Menu } from 'react-burger-menu'
import { Link } from "gatsby"
import { IconMenu, IconCross } from 'components/icons' 
import { Button } from 'components/ui'
import cn from 'classnames'
import { Logo } from 'components/common'
import PropTypes from 'prop-types'
import { isNil } from 'ramda'

export default class MobileMenu extends Component {

  showSettings (event) {
    event.preventDefault()
  }

  state = {
    menuOpen: false
  }

  handleStateChange(state) {
    this.setState({menuOpen: state.isOpen})  
    window.setTimeout(() => { 
      if (state.isOpen) {
        document.activeElement.blur()
      }
    })
  }
  closeMenu () {
    this.setState({menuOpen: false})
  }
  toggleMenu () {
    this.setState(state => ({menuOpen: !state.menuOpen}))
  }
  
  render() {
    const {
      onClickLogIn,
      onClickSignUp,
      isUserLoggedIn,
      onClickLogout,
      onClickOrder,
      hasOrder,
    } = this.props

    const AuthButtons = () => (
      <div className={css.authButtons}>
        <div
          className={cn('label-2', css.logIn)}
          onClick={onClickLogIn}
          role='button'
          tabIndex='0'
          onKeyDown={() => {}}
        >
          Войти
        </div>
        <Button
          text="Зарегистрироваться"
          className={css.signUp}
          ghost
          smaller
          onClick={onClickSignUp}
        />
      </div>
    )
    
    const MobileMenuAuth = () => (
      <ul className={css.authList}>
        <li>
          <Link to="/account/" className="label-2">
            Личные данные
          </Link>
        </li>
        <li>
          <Link to="/account/settings/" className="label-2">
            Настройки аккаунта
          </Link>
        </li>
        <li>
          <span
            onClick={onClickLogout}
            className="label-2 pointer"
            role='button'
            tabIndex='0'
            onKeyDown={() => {}}
          >
            Выйти
          </span>
        </li>
      </ul>
    )
    
    return (
      <Menu className={css.mobileMenu} 
            isOpen={this.state.menuOpen}
            onStateChange={(state) => this.handleStateChange(state)} 
            right 
            customBurgerIcon={ <IconMenu /> }
            customCrossIcon={ <IconCross /> }
            burgerButtonClassName={css.mobileMenuButton}
            overlayClassName={css.mobileMenuOverlay}
            menuClassName={css.mobileMenuBody}
            itemListClassName={css.mobileMenuList}
            crossButtonClassName={css.mobileMenuCrossButton}
      >
        <a href="/" 
           aria-label="check"
           className={css.mobileLogo}>
          <Logo />
        </a>
        <Link to='/what-is-shabbath' 
              className={cn(css.menuItem, 'label-2')} 
              onClick={() => this.closeMenu()}>
          Что такое Шабат? 
        </Link>
        <Link to='/donate' 
              className={cn(css.menuItem, 'label-2')} 
              onClick={() => this.closeMenu()}>
          Поддержать проект 
        </Link>
        <Link to='/archive' 
              className={cn(css.menuItem, 'label-2')} 
              onClick={() => this.closeMenu()}>
          Архив
        </Link>
        <Link to='/reviews' 
              className={cn(css.menuItem, css.lastItem, 'label-2')} 
              onClick={() => this.closeMenu()}>
          Отзывы 
        </Link>
        {
          hasOrder &&
            <span
              className={cn(css.menuItem, 'label-2 primary-color')} 
              onClick={onClickOrder}
              role='button'
              tabIndex='0'
              onKeyDown={() => {}}
            >
              Оставить заявку на получение набора
            </span>
        }

        {!isNil(isUserLoggedIn) && isUserLoggedIn ? <MobileMenuAuth /> : <AuthButtons />}

      </Menu>
    )
  }
}

MobileMenu.propTypes = {
  onClickLogIn: PropTypes.func.isRequired,
  onClickSignUp: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.oneOfType([PropTypes.bool.isRequired])
}
import React from 'react'
import css from './style.module.scss'
import Pic1SvgSrc from './img/pic1.inline.svg'
import Pic2SvgSrc from './img/pic2.inline.svg'
import Pic3SvgSrc from './img/pic3.inline.svg'

export default function () {
  return (
    <div className={css.shabbathSet}>
      <div className='container'>
        <h2 className={css.title}>Набор для Шабата у вас дома</h2>

        <div className={css.list}>

          <div className={css.listItem}>
            <div className={css.listItemText}>
              <div className={css.listItemIndex}>1</div>
              <div>
                <h3>Зарегистрируйтесь</h3>
                <p>
                  Пройдите простую<br />регистрацию на сайте.
                </p>
              </div>
            </div>

            <div className={css.listItemPic}>
              <Pic1SvgSrc />
            </div>
          </div>

          <div className={css.listItem}>
            <div className={css.listItemText}>
              <div className={css.listItemIndex}>2</div>
              <div>
                <h3>Оставьте заявку<br/> на подписку</h3>
                <p>
                  Оставьте заявку на получение подарочного набора для шаббата
                  для себя или для своих близких в другом городе.
                </p>
              </div>
            </div>

            <div className={css.listItemPic}>
              <Pic2SvgSrc />
            </div>
          </div>

          <div className={css.listItem}>
            <div className={css.listItemText}>
              <div className={css.listItemIndex}>3</div>
              <div>
                <h3>Получите ваш набор</h3>
                <p>
                  Распакуйте свой набор, и накройте субботний стол.
                </p>
              </div>
            </div>

            <div className={css.listItemPic}>
              <Pic3SvgSrc />
            </div>
          </div>
          
        </div>
      
      </div>
    </div>
  )
}

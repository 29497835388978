import React from 'react'
import { Logo } from 'components/common'
import cn from 'classnames'
import css from './style.module.scss'
import {
  // IconVK,
  IconFacebook,
  IconInstagram,
} from 'components/icons'
import imgGolovaLogo from './img/golova.svg'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'

FooterView.propTypes = {
  onClickFeedback: PropTypes.func.isRequired
}

export default function FooterView({
  onClickFeedback
}) {
  return (
    <div className={css.footer}>
      <div className='container'>
        <div className={css.top}>
          <div className={css.topLeft}>
            <div className={css.logo}>
              <Link to="/" className={css.logoImage}>
                <Logo white />
              </Link>
            </div>
            <div className={css.social}>
              {/* <a href="https://www.facebook.com/FJCofCISRU/" target="_blank" rel="noreferrer">
                <IconFacebook />
              </a> */}
              {/* <a href="https://instagram.com/shabbatshalomru?igshid=zu1he8egq3y5" target="_blank" rel="noreferrer">
                <IconInstagram />
              </a> */}
            </div>
          </div>
          <div className={css.topRight}>
            <div className={css.contacts}>
              <Link
                to='/contacts'
                className={cn(css.contact, css.contactLink, 'label-2 white a')}
              >
                Контакты
              </Link>
              <button
                onClick={onClickFeedback}
                className={cn(css.contact, css.contactLink, 'label-2 white a')}
              >
                Связаться с нами
              </button>
              <Link
                to='/donate'
                className={cn(css.contact, 'button --ghost')}
              >
                Поддержать проект
              </Link>
            </div>
          </div>
        </div>
        <div className={css.bottom}>
          <div className={css.bottomLeft}>
            <div className={css.legal}>
              <p className={cn(css.copyright, 'caption white')}>
                ©{ (new Date()).getFullYear() }, ШАБАТ ШАЛОМ
              </p>
              <p className={css.legalPage}>
                <a
                  href="/privacy-policy/"
                  target="_blank"
                  className='white caption'
                >
                  Политика обработки персональных данных
                </a>
              </p>

              <p className={css.legalPage}>
                <a
                  href="/terms-of-service/"
                  target="_blank"
                  className='white caption'
                >
                  Пользовательское соглашение
                </a>
              </p>

              <p className={css.legalPage}>
                <a
                  href="/cookies-policy/"
                  target="_blank"
                  className='white caption'
                >
                  Правила cookie
                </a>
              </p>
            </div>
          </div>
          <div className={css.bottomRight}>
            <div className={cn(css.golova, 'caption')}>
              Разработано в&nbsp;&nbsp;
              <a
                href='https://golovamedia.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img src={imgGolovaLogo} alt='Golova Media'/>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
import React from 'react'
import css from './style.module.scss'
import cn from 'classnames'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import * as popupActions from 'components/popups/actions'
import { IconCross } from 'components/icons'

const mapDispatchToProps = dispatch => ({
  popupActions: bindActionCreators(popupActions, dispatch)
})

export default connect(null, mapDispatchToProps)(PopupLayout)

function PopupLayout({
  popupActions,
  children,
  title
}) {
  return (
    <div className={css.popup}>

      <div
        className={css.popupClose}
        onClick={e => popupActions.closePopup()}
        role='button'
        tabIndex='0'
        onKeyDown={() => {}}
      >
        <IconCross />
      </div>

      {title &&
        <p className={cn('h2-like popup-title')}>{title}</p>
      }

      <div
        className={css.popupBody}
      >
        {children}
      </div>

    </div>
  )
}


import React, { useState, useEffect } from 'react'
import MobileMenu from './MobileMenu'
import { connect, useDispatch } from 'react-redux'
import { bindActionCreators } from 'redux'
import { openLogInPopup, openSignUpPopup, logout } from 'components/user/actions'
import PropTypes from 'prop-types'
import { navigate } from 'gatsby'

MobileHeaderContainer.propTypes = {
  openLogInPopup: PropTypes.func.isRequired,
  openSignUpPopup: PropTypes.func.isRequired
}

const mapStateToProps = state => ({
  user: state.user,
  authToken: state.user.authToken,
  hasOrder: state.orders.has_order,
})

const mapDispatchToProps = dispatch => ({
  openLogInPopup: bindActionCreators(openLogInPopup, dispatch),
  openSignUpPopup: bindActionCreators(openSignUpPopup, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MobileHeaderContainer)

function MobileHeaderContainer({
  openLogInPopup,
  openSignUpPopup,
  user,
  authToken,
  hasOrder,
}) {
  const [isUserLoggedIn, setIsUserLoggedIn] = useState(null)

  const onClickLogInHandler = () => openLogInPopup()

  const onClickSignUpHandler = () => openSignUpPopup()

  useEffect(() => {
    setIsUserLoggedIn(!!user.authToken)
  }, [user])

  const dispatch = useDispatch()

  function onClickLogoutHandler() {
    dispatch(logout())
  }

  const onClickOrderHandler = e => {
    if (authToken) {
      navigate('/make-order/')
    } else {
      openLogInPopup()
    }
  }

  return <MobileMenu
    onClickLogIn={onClickLogInHandler}
    onClickSignUp={onClickSignUpHandler}
    isUserLoggedIn={isUserLoggedIn}
    onClickLogout={onClickLogoutHandler}
    onClickOrder={onClickOrderHandler}
    hasOrder={hasOrder}
  />
}

import React from 'react'
import HeroView from './View'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { navigate } from 'gatsby'
import { openLogInPopup } from 'components/user/actions'

HeroContainer.propTypes = {
}

const mapStateToProps = state => ({
  authToken: state.user.authToken,
  hasOrder: state.orders.has_order,
})

const mapDispatchToProps = dispatch => ({
  openLogInPopup: bindActionCreators(openLogInPopup, dispatch)
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeroContainer)

function HeroContainer({
  openLogInPopup,
  authToken,
  hasOrder,
}) {
  const onClickButtonHandler = e => {
    if (authToken) {
      navigate('/make-order/')
    } else {
      openLogInPopup()
    }
  }

  return <HeroView
    onClickButton={onClickButtonHandler}
    hasOrder={hasOrder}
  />
}
